import React, { useState } from "react";
import axios from "axios";
import "./AuthForm.css";
import SignInForm from "./SignInForm"; // Import without ".js" extension
import Overlay from "./Overlay"; // Import without ".js" extension

const AuthForm = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [MaNV, setMaNV] = useState("");
  const [MatKhau, setMatKhau] = useState("");
  const [error, setError] = useState("");

  const handleSignUpClick = () => {
    setIsSignUp(true);
  };

  const handleSignInClick = () => {
    setIsSignUp(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://server.vietlonghung.com.vn/login",
        // "http://localhost:30009/login",
        {
          MaNV,
          MatKhau,
        }
      );

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("_idNV", response.data.user._idNV);
        localStorage.setItem("manv", response.data.user.MaNV);
        localStorage.setItem("tennv", response.data.user.TenNV);
        localStorage.setItem("role", response.data.user.Role);
        localStorage.setItem("idrole", response.data.user.idRole);
        localStorage.setItem("phongban", response.data.user.PhongBan);
        localStorage.setItem("idphongban", response.data.user.idPhongBan);
        window.location.href = "/";
        // <Navigate to="/" replace={true} />;
      } else {
        setError("Mã NV hoặc Mật khẩu sai");
      }
    } catch (err) {
      setError("Mã NV hoặc Mật khẩu sai");
    }
  };

  return (
    <div className="body">
      <div
        className={`container_auth ${isSignUp ? "right-panel-active" : ""}`}
        id="container"
      >
        <div className="form-container sign-in-container">
          <SignInForm
            MaNV={MaNV}
            setMaNV={setMaNV}
            MatKhau={MatKhau}
            setMatKhau={setMatKhau}
            error={error}
            handleSubmit={handleSubmit}
          />
        </div>
        <div className="overlay-container">
          <Overlay
            onSignUpClick={handleSignUpClick}
            onSignInClick={handleSignInClick}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
